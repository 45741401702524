/**
 * @author liuzhenkun
 * @date 2017/12/29-下午5:01
 * @file agreement
 */

import Webview from 'moneycan/common/resource/easycash-indo-webview';

import Mustache from 'ssr-common/util/mustache';

const DEFAULT_AGREEMENT_KEY = 'EASYCASH_IDN_LOAN';

const AGREEMENTS_WITHOUT_USER_INFO = [
    'EASYCASH_LENDER_AGREEMENT',
    'EASYCASH_LOAN_TEMPLATE_AGREEMENT'
];

export default {
    name: 'agreement',

    title: 'Perjanjian peminjaman',

    data() {
        return {
            agreement: {
                key: DEFAULT_AGREEMENT_KEY,
                content: ''
            }
        };
    },

    mounted() {
        const vm = this;
        const {$route: {query}} = vm;

        const key = query.key || DEFAULT_AGREEMENT_KEY;
        const id = query.id || null;

        const getAgreement = AGREEMENTS_WITHOUT_USER_INFO.includes(key)
            ? Webview.getContentByKey : Webview.getAgreementByKey;

        getAgreement({key, id})
            .then(({body: {body: {content: template, values}}}) => {
                const content = Mustache.render(template, values);

                Object.assign(vm.agreement, {
                    key,
                    content
                });
            })
            .catch(x => x);
    }
};
