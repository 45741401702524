/*
 * @Author: liuzhenkun
 * @Date: 2018-07-10 12:42:18
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2018-07-10 13:23:45
 */

import http from 'ssr-common/vue/vue-http';

export const api = {
    getAccountDetails: '/api/cashloan/accountDetails', // profile

    getAgreementByKey: '/api/operation/agreement/getByKey', // 获取协议
    getContentByKey: '/api/operation/agreement/getContentByKey', // 获取不需要用户信息的协议

    // 聚信力相关
    getJuxinliInfo: '/api/loan/getJuxinliInfo',
    updateJuxinliDataStatus: '/api/loan/updateJuxinliDatasourceStatus',
    finishJuxinliAuthorization: '/api/loan/finishJuxinliAuthorization'
};

export default {
    getAccountDetails() {
        return http.get(api.getAccountDetails);
    },

    getAgreementByKey({key, id}) {
        return http.get(api.getAgreementByKey, {params: {key, ...(id ? {id} : {})}});
    },

    getContentByKey({key}) {
        return http.get(api.getContentByKey, {params: {key}});
    },

    getJuxinliInfo() {
        return http.get(api.getJuxinliInfo);
    },

    updateJuxinliDataStatus(data) {
        return http.post(api.updateJuxinliDataStatus, data);
    },

    finishJuxinliAuthorization() {
        return http.get(api.finishJuxinliAuthorization);
    }
};
