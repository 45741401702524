var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agreement" }, [
    _c(
      "div",
      { staticClass: "yqg-island" },
      [_c("yqg-md-preview", { attrs: { "md-text": _vm.agreement.content } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }