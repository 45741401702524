/*
 * @Author: liuzhenkun
 * @Date: 2018-07-10 13:27:03
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2018-07-10 13:27:03
 */

import Mustache from 'mustache';

Mustache.Context.prototype.originLookup = Mustache.Context.prototype.lookup;

const FormatterMap = {
    percent: value => {
        if (!value && value !== 0) return '';

        const temp = Math.floor(value * 10000) / 100;

        return `${temp}%`;
    }
};

const applyFilter = (expression, filterStr) => {
    const filterName = filterStr.replace(/\s/g, '');

    if (!filterName) return expression;
    if (!FormatterMap[filterName]) return expression;

    return FormatterMap[filterName](expression);
};

Object.assign(Mustache.Context.prototype, {
    lookup(name) {
        const self = this;
        const [expression, filter = ''] = name.split('|').map(item => item.trim());

        const value = self.originLookup(expression);

        return applyFilter(value, filter);
    }
});

export default Mustache;
